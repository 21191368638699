import React from 'react';

function StoreLimitModal({ isVisible, onClose, onUpgrade }) {
    if (!isVisible) return null; // Modal görünmüyorsa hiç render etme

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
                <div className="bg-white rounded-lg shadow-xl z-50 p-6">
                    <h2 className="text-xl font-bold mb-4">Store Limit Reached</h2>
                    <p className="mb-4">You have reached the store limit for your current subscription package. Please upgrade your plan to add more stores.</p>
                    <div className="flex justify-end space-x-4">
                        <button 
                            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600" 
                            onClick={onUpgrade}
                        >
                            Upgrade Plan
                        </button>
                        <button 
                            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400" 
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoreLimitModal;
