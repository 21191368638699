import React from 'react';

function StoreDeleteModal({ isVisible, onClose, onConfirm }) {
    if (!isVisible) return null; // Modal görünmüyorsa render etme

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
                <div className="bg-white rounded-lg shadow-xl z-50 p-6">
                    <h2 className="text-xl font-bold mb-4">Are you sure?</h2>
                    <p className="mb-4">This will permanently remove the store and all related data.</p>
                    <div className="flex justify-end space-x-4">
                        <button 
                            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button 
                            className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                            onClick={onConfirm}
                        >
                            Remove Store
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoreDeleteModal;
