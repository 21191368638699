import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

const ContactForm = () => {
    const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm();

    const onSubmit = async (data) => {
        try {
            const response = await fetch('https:/etsyhelper.onrender.com/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),  // Form verilerini JSON formatında gönderiyoruz
            });

            if (response.ok) {
                alert("Mesajınız başarıyla gönderildi!");
                reset();  // Formu sıfırla
            } else {
                alert("Mesaj gönderilirken bir hata oluştu.");
            }
        } catch (error) {
            alert("Sunucuya bağlanılamadı. Lütfen tekrar deneyin.");
            console.error("Hata:", error);
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">İletişim Formu</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* İsim alanı */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="name">
                        Adınız
                    </label>
                    <input
                        {...register('name', { required: 'Ad alanı zorunludur' })}
                        id="name"
                        placeholder="Adınızı girin"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
                </div>

                {/* E-posta alanı */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="email">
                        E-posta Adresiniz
                    </label>
                    <input
                        {...register('email', {
                            required: 'E-posta adresi zorunludur',
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Geçerli bir e-posta adresi girin'
                            }
                        })}
                        id="email"
                        placeholder="E-posta adresinizi girin"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
                </div>

                {/* Mesaj alanı */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="message">
                        Mesajınız
                    </label>
                    <textarea
                        {...register('message', { required: 'Mesaj alanı zorunludur' })}
                        id="message"
                        placeholder="Mesajınızı buraya yazın"
                        rows="5"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    ></textarea>
                    {errors.message && <span className="text-red-500 text-sm">{errors.message.message}</span>}
                </div>

                {/* Gönder butonu */}
                <div className="text-center">
                    <button
                        type="submit"
                        className={`px-6 py-2 rounded-md text-white font-semibold ${isSubmitting ? 'bg-gray-400' : 'bg-indigo-500 hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50'}`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Gönderiliyor...' : 'Mesaj Gönder'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
