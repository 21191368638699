import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { toast } from 'react-toastify';

function StaticImageUpload({ profileId }) {
  const [files, setFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.length > 0) {
      toast.error('Sadece JPG, JPEG ve PNG formatında dosyalar yükleyebilirsiniz.');
      return;
    }

    if (files.length + uploadedImages.length + acceptedFiles.length > 4) {
      toast.error('En fazla 4 resim yükleyebilirsiniz.');
      return;
    }
    setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    maxSize: 5242880,
    validator: (file) => {
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        return {
          code: 'wrong-file-type',
          message: 'Sadece JPG, JPEG ve PNG formatında dosyalar yükleyebilirsiniz.'
        };
      }
      return null;
    }
  });

  const getStaticImages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://etsyhelper.onrender.com/get-static-images', {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          profile_id: profileId
        }
      });

      if (response.status === 200) {
        setUploadedImages(response.data);
      }
    } catch (error) {
      console.error('Kayıtlı resimleri çekerken hata:', error);
      toast.error('Kayıtlı sabit resim bulunamadı.');
    }
  };

  useEffect(() => {
    getStaticImages();
  }, []);

  const handleUpload = async () => {
    if (files.length === 0) {
      toast.error('Lütfen resim seçin.');
      return;
    }

    const formData = new FormData();
    formData.append('profile_id', profileId);
    files.forEach((file) => {
      formData.append('static_images', file);
    });

    try {
      setUploading(true);
      setUploadProgress(0);
      
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://etsyhelper.onrender.com/save-static-images',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.status === 201) {
        toast.success('Resimler başarıyla yüklendi!');
        getStaticImages();
        setFiles([]);
        setUploadProgress(0);
      }
    } catch (error) {
      console.error('Resim yükleme hatası:', error);
      toast.error('Yükleme sırasında bir hata oluştu.');
    } finally {
      setUploading(false);
    }
  };

  const deleteStaticImage = async (imageId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `https://etsyhelper.onrender.com/delete-static-image/${imageId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        toast.success('Sabit resim başarıyla silindi!');
        getStaticImages();
      }
    } catch (error) {
      console.error('Resim silme hatası:', error);
      toast.error('Silme işlemi sırasında bir hata oluştu.');
    }
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Header */}
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-2xl font-semibold text-gray-800">Varyasyon Profili İçin Sabit Resimler</h2>
        <p className="mt-2 text-sm text-gray-600">
          En fazla 4 adet JPG, JPEG veya PNG formatında resim yükleyebilirsiniz.
        </p>
      </div>

      <div className="p-6 space-y-6">
        {/* Dropzone */}
        <div
          {...getRootProps()}
          className={`
            border-2 border-dashed rounded-lg p-8 cursor-pointer
            transition duration-200 ease-in-out
            ${isDragActive 
              ? 'border-purple-500 bg-purple-50' 
              : 'border-gray-300 hover:border-purple-500 hover:bg-purple-50'
            }
          `}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center justify-center space-y-4">
            {/* Upload Icon */}
            <svg
              className="w-12 h-12 text-gray-400"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>

            <div className="text-center">
              {isDragActive ? (
                <p className="text-purple-500 font-medium">Dosyaları buraya bırakın...</p>
              ) : (
                <>
                  <p className="text-gray-600">Resimleri buraya sürükleyin</p>
                  <p className="text-gray-400 text-sm mt-1">veya dosya seçmek için tıklayın</p>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Upload Progress */}
        {uploading && (
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm text-gray-600">
              <span>Yükleniyor...</span>
              <span>{uploadProgress}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-purple-500 h-2 rounded-full transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
          </div>
        )}

        {/* Image Previews */}
        {(uploadedImages.length > 0 || files.length > 0) && (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {/* Uploaded Images */}
            {uploadedImages.map((image, idx) => (
              <div key={idx} className="relative group aspect-square">
                <img
                  src={image.static_image_url}
                  alt="uploaded"
                  className="w-full h-full object-cover rounded-lg"
                />
                
                {/* Always Visible Delete Icon (Optional) */}
                <button
                  onClick={() => deleteStaticImage(image.id)}
                  className="absolute top-2 right-2 p-2 bg-red-500 bg-opacity-75 hover:bg-opacity-100 text-white rounded-full shadow-lg transform transition-transform duration-200 hover:scale-110"
                  aria-label="Delete image"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            ))}
            {/* New File Previews */}
            {files.map((file, idx) => (
              <div key={idx} className="relative aspect-square">
                <img
                  src={URL.createObjectURL(file)}
                  alt="preview"
                  className="w-full h-full object-cover rounded-lg"
                />
                <button
                  onClick={() => setFiles(files.filter((_, i) => i !== idx))}
                  className="absolute top-2 right-2 p-2 bg-red-500 hover:bg-red-600 text-white rounded-full transition duration-200"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Upload Button */}
        <div className="flex justify-end">
          <button
            onClick={handleUpload}
            disabled={uploading || files.length === 0}
            className={`
              flex items-center px-4 py-2 rounded-lg text-white
              transition duration-200
              ${uploading || files.length === 0
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-purple-500 hover:bg-purple-600'
              }
            `}
          >
            {uploading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Yükleniyor
              </>
            ) : (
              <>
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
                Resimleri Yükle
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default StaticImageUpload;