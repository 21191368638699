import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaUserCircle, FaBars, FaBox, FaChevronDown } from 'react-icons/fa';
import { FaBoxesStacked } from 'react-icons/fa6';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

Modal.setAppElement('#root');

function Header({ toggleSidebar }) {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      fetchStores();
    }
  }, [isAuthenticated]);

  const fetchStores = async () => {
    try {
      const token = localStorage.getItem('token');
      const storesResponse = await axios.get('https://etsyhelper.onrender.com/stores/list', {
        headers: { Authorization: `Bearer ${token}` },
      });

      setStores(storesResponse.data);

      if (storesResponse.data.length > 0) {
        const selectedStoreResponse = await axios.get('https://etsyhelper.onrender.com/stores/get-selected-store', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const lastSelectedStoreId = selectedStoreResponse.data.last_selected_store_id;
        if (lastSelectedStoreId) {
          setSelectedStore(lastSelectedStoreId);
          localStorage.setItem('last_selected_store', lastSelectedStoreId);
        } else {
          setSelectedStore(storesResponse.data[0].id);
        }
      } else {
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const handleStoreChange = async (storeId) => {
    setSelectedStore(storeId);
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://etsyhelper.onrender.com/stores/select-store', 
        { store_id: storeId }, 
        { headers: { Authorization: `Bearer ${token}` }}
      );
      window.location.reload();
    } catch (error) {
      console.error('Error saving selected store:', error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="bg-white border-b border-gray-200 fixed top-0 left-0 right-0 z-30">
        <div className="h-16 px-4 flex items-center justify-between">
          {/* Sol Bölüm - Hamburger Menü */}
          <div className="sidebar flex items-center">
            <button 
              onClick={toggleSidebar}
              className="p-2 rounded-lg text-gray-600 hover:bg-gray-100 transition-colors duration-200"
            >
              <FaBars className="w-5 h-5" />
            </button>
          </div>

          {/* Orta Bölüm - Mağaza Seçimi */}
          <div className="header-store-selector flex-1 flex justify-center px-4">
            {isAuthenticated && stores.length > 0 ? (
              <div className="relative inline-block">
                <div className="relative">
                  <select
                    value={selectedStore || ""}
                    onChange={(e) => handleStoreChange(e.target.value)}
                    className="appearance-none bg-gray-50 border border-gray-200 text-gray-700 py-2 pl-4 pr-10 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent hover:bg-gray-100 transition-colors duration-200"
                  >
                    {stores.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.shop_name}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <FaChevronDown className="w-4 h-4 text-gray-500" />
                  </div>
                </div>
              </div>
            ) : (
              <button 
                onClick={() => navigate('/my-account/stores')}
                className="inline-flex items-center px-4 py-2 rounded-lg text-purple-600 hover:bg-purple-50 transition-colors duration-200"
              >
                <FaBoxesStacked className="w-5 h-5 mr-2" />
                <span className="font-medium">Mağaza Seçimi Yapın</span>
              </button>
            )}
          </div>

          {/* Sağ Bölüm - Kullanıcı Menüsü */}
          <div className="flex items-center">
            {isAuthenticated ? (
              <div className="relative">
                <button
                  onClick={toggleMenu}
                  className="flex items-center px-3 py-2 rounded-lg text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                >
                  <FaUserCircle className="w-5 h-5 text-gray-600" />
                  <span className="ml-2 font-medium">Hesabım</span>
                  <FaChevronDown className="w-4 h-4 ml-2 text-gray-500" />
                </button>

                {menuOpen && (
                  <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg border border-gray-200 py-1">
                    <Link 
                      to="/my-account/account-details" 
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => setMenuOpen(false)}
                    >
                      Hesap Detayları
                    </Link>
                    <Link 
                      to="/my-account/subscription" 
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => setMenuOpen(false)}
                    >
                      Abonelik Yönetimi
                    </Link>
                    <Link 
                      to="/my-account/stores" 
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => setMenuOpen(false)}
                    >
                      Mağazalarım
                    </Link>
                    <div className="border-t border-gray-100 my-1"></div>
                    <button 
                      onClick={handleLogout}
                      className="w-full flex items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                    >
                      Oturumu Kapat
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <Link 
                  to="/login" 
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                >
                  Giriş Yap
                </Link>
                <Link 
                  to="/register" 
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200"
                >
                  Kayıt Ol
                </Link>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Content Padding için boşluk */}
      <div className="h-16"></div>

      {/* Mağaza Ekleme Modal */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-2xl p-6 max-w-md w-full mx-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <div className="text-center">
          <FaBoxesStacked className="mx-auto h-12 w-12 text-purple-500" />
          <h2 className="mt-4 text-2xl font-semibold text-gray-900">Mağaza Ekleme</h2>
          <p className="mt-2 text-gray-600">
            Henüz ekli bir mağazanız bulunmuyor. Hemen yeni bir mağaza ekleyin.
          </p>
          <div className="mt-6">
            <button
              onClick={() => {
                navigate('/my-account/stores');
                setShowModal(false);
              }}
              className="w-full px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200"
            >
              Mağaza Ekle
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="mt-2 w-full px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
            >
              Kapat
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Header;