import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStore, FaBox, FaDollarSign, FaCrown, FaHistory, FaFilePdf, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

function SubscriptionPage() {
  const [stores, setStores] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalAmountDue, setTotalAmountDue] = useState(null);
  const [packages, setPackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [billings, setBillings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Frontend totalAmountDue:", totalAmountDue);
    console.log(typeof(totalAmountDue));
  }, [totalAmountDue]);

  useEffect(() => {
    fetchData();
    fetchBillingTotal();
  }, []);

  // Genel verileri al
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      // Mağaza verilerini al
      const storesResponse = await axios.get('https://etsyhelper.onrender.com/stores/list', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const storesData = storesResponse.data;
      setStores(storesData);

      // Toplam ürün sayısını hesapla
      const totalProductsCount = storesData.reduce((sum, store) => sum + store.product_count, 0);
      setTotalProducts(totalProductsCount);

      // Abonelik paketlerini al
      const packagesResponse = await axios.get('https://etsyhelper.onrender.com/subscription/packages', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPackages(packagesResponse.data);

      // Kullanıcının mevcut aboneliğini al
      const currentPackageResponse = await axios.get('https://etsyhelper.onrender.com/subscription/current', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCurrentPackage(currentPackageResponse.data);

      // Fatura verilerini al
      const billingResponse = await axios.get('https://etsyhelper.onrender.com/billing', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBillings(billingResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Abonelik verileri alınamadı.');
    } finally {
      setLoading(false); // Veriler alındıktan sonra loading'i kapat
    }
  };

  // Bu ayki toplam ödenecek tutarı almak için backend API çağrısı
  const fetchBillingTotal = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://etsyhelper.onrender.com/subscription/billing_total', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const totalAmount = response.data.total_amount; // Gelen veriyi al
      setTotalAmountDue(parseFloat(totalAmount)); // State'e set et
    } catch (error) {
      console.error('Error fetching billing total:', error);
      toast.error('Toplam fatura bilgisi alınamadı.');
    }
  };
  const handleViewInvoice = (invoiceUrl) => {
    window.open(invoiceUrl, '_blank');
  };

  const getStatusBadge = (isPaid) => {
    if (isPaid === 1) {
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          <FaCheckCircle className="mr-1 mt-0.5" />
          Ödendi
        </span>
      );
    }
    return (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        <FaTimesCircle className="mr-1 mt-0.5" />
        Ödenmedi
      </span>
    );
  };

  // Abonelik yükseltme veya düşürme işlemi
  const handleUpgrade = async (packageId) => {
    try {
      const token = localStorage.getItem('token');

      // API isteği yaparak paketi yükseltme veya düşürme işlemi gerçekleştir
      const response = await axios.post('https://etsyhelper.onrender.com/subscription/update_subscription', 
        { package_id: packageId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        const message = response.data.message;
        toast.success(message); // Başarı mesajını göster

        // Verileri tekrar yükle (Mevcut paket vs. bilgileri güncellemek için)
        fetchData();
      }
    } catch (error) {
      // Hata mesajlarını kontrol et ve uygun hata mesajını göster
      if (error.response && error.response.data.message) {
        toast.error(error.response.data.message); // Hata mesajını göster
      } else {
        console.error('Error updating subscription:', error);
        toast.error('Abonelik güncellenemedi.');
      }
    }
  };

  const StatCard = ({ icon: Icon, title, value, color }) => (
    <div className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
      <div className="flex items-center space-x-4">
        <div className={`p-3 rounded-xl ${color}`}>
          <Icon className="w-6 h-6 text-white" />
        </div>
        <div>
          <h3 className="text-gray-500 text-sm font-medium">{title}</h3>
          <p className="text-2xl font-bold mt-1">{value}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <StatCard
          icon={FaStore}
          title="Mağazalar"
          value={stores.length}
          color="bg-blue-500"
        />
        <StatCard
          icon={FaBox}
          title="Toplam Ürün"
          value={totalProducts}
          color="bg-green-500"
        />
        <StatCard
          icon={FaDollarSign}
          title="Bu Ay Ödenecek"
          value={totalAmountDue !== null && typeof totalAmountDue === 'number' && !isNaN(totalAmountDue)
            ? `$${totalAmountDue.toFixed(2)}`
            : 'Yükleniyor...'}
          color="bg-purple-500"
        />
      </div>

      {/* Stores Section */}
      <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-bold mb-6 flex items-center">
          <FaStore className="mr-2 text-blue-500" />
          Mağazalarınız
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Mağaza Adı
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Eklenme Tarihi
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ürün Sayısı
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {stores.map((store) => (
                <tr key={store.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {store.shop_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(store.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {store.product_count}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Subscription Packages */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-6 flex items-center">
          <FaCrown className="mr-2 text-yellow-500" />
          Abonelik Paketleri
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className={`bg-white rounded-2xl p-6 shadow-lg transition-all duration-300 
                ${currentPackage && currentPackage.id === pkg.id
                  ? 'ring-2 ring-blue-500 transform scale-105'
                  : 'hover:shadow-xl'}`}
            >
              <h3 className="text-xl font-bold mb-4">{pkg.name}</h3>
              <div className="text-3xl font-bold mb-4">${pkg.price}<span className="text-sm text-gray-500">/ay</span></div>
              <div className="space-y-3 mb-6">
                <div className="flex items-center text-gray-600">
                  <FaStore className="mr-2 text-blue-500" />
                  <span>Mağaza Limiti: {pkg.store_limit}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <FaBox className="mr-2 text-green-500" />
                  <span>Ürün Limiti: {pkg.product_limit}</span>
                </div>
              </div>
              {currentPackage && currentPackage.id !== pkg.id && (
                <button
                  onClick={() => handleUpgrade(pkg.id)}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-xl transition-colors duration-300"
                >
                  Pakete Geç
                </button>
              )}
              {currentPackage && currentPackage.id === pkg.id && (
                <div className="text-center py-2 text-blue-500 font-medium">
                  Mevcut Paketiniz
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Billing History */}
      <div className="bg-white rounded-2xl shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-6 flex items-center">
          <FaHistory className="mr-2 text-gray-500" />
          Fatura Geçmişi
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Abonelik Paketi
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tutar
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fatura Tarihi
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Yenilenme Tarihi
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Durum
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fatura
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {billings.map((bill, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {bill.package_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    ${bill.amount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(bill.billing_date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(bill.renewal_date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {getStatusBadge(bill.is_paid)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {bill.invoice_url && (
                      <button
                        onClick={() => handleViewInvoice(bill.invoice_url)}
                        className="text-blue-600 hover:text-blue-800 flex items-center"
                      >
                        <FaFilePdf className="mr-1" />
                        Görüntüle
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default SubscriptionPage;