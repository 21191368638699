import React, { useState, useEffect, memo } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { FaUser, FaLock, FaCheck, FaExclamationCircle } from 'react-icons/fa';

// Input bileşenini memo ile sarmalıyoruz
const Input = memo(({ label, type, name, value, onChange, readOnly = false }) => (
  <div className="relative mb-6">
    <input
      type={type}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      className={`
        peer w-full h-12 px-4 pt-4 bg-white border rounded-xl
        focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent
        ${readOnly ? 'bg-gray-50' : ''}
        ${value ? 'pt-4' : ''}
      `}
      placeholder=" "
    />
    <label
      htmlFor={name}
      className="absolute left-4 top-1 text-xs font-medium text-gray-500 transition-all
        peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400
        peer-focus:top-1 peer-focus:text-xs peer-focus:text-purple-500"
    >
      {label}
    </label>
  </div>
));

// Form bileşenlerini ayrı komponentlere ayırıyoruz
const ProfileForm = memo(({ formData, onChange, onSubmit, loading }) => (
  <form onSubmit={onSubmit}>
    <Input
      label="First Name"
      type="text"
      name="first_name"
      value={formData.first_name}
      onChange={onChange}
    />
    <Input
      label="Last Name"
      type="text"
      name="last_name"
      value={formData.last_name}
      onChange={onChange}
    />
    <Input
      label="Email Address"
      type="email"
      name="email"
      value={formData.email}
      onChange={onChange}
      readOnly
    />
    <Input
      label="Phone Number"
      type="tel"
      name="phone"
      value={formData.phone}
      onChange={onChange}
    />
    <button
      type="submit"
      disabled={loading}
      className={`
        w-full h-12 mt-6 rounded-xl text-white font-medium
        transition-all duration-200
        ${loading
          ? 'bg-purple-300 cursor-not-allowed'
          : 'bg-purple-600 hover:bg-purple-700 active:transform active:scale-98'
        }
      `}
    >
      {loading ? 'Updating...' : 'Save Changes'}
    </button>
  </form>
));

const PasswordForm = memo(({ passwordData, onChange, onSubmit, loading }) => (
  <form onSubmit={onSubmit}>
    <Input
      label="Current Password"
      type="password"
      name="currentPassword"
      value={passwordData.currentPassword}
      onChange={onChange}
    />
    <Input
      label="New Password"
      type="password"
      name="newPassword"
      value={passwordData.newPassword}
      onChange={onChange}
    />
    <Input
      label="Confirm New Password"
      type="password"
      name="confirmPassword"
      value={passwordData.confirmPassword}
      onChange={onChange}
    />
    <button
      type="submit"
      disabled={loading}
      className={`
        w-full h-12 mt-6 rounded-xl text-white font-medium
        transition-all duration-200
        ${loading
          ? 'bg-purple-300 cursor-not-allowed'
          : 'bg-purple-600 hover:bg-purple-700 active:transform active:scale-98'
        }
      `}
    >
      {loading ? 'Updating...' : 'Change Password'}
    </button>
  </form>
));

const AccountDetails = () => {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get('https://etsyhelper.onrender.com/user', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFormData({
          first_name: response.data.first_name || '',
          last_name: response.data.last_name || '',
          email: response.data.email || '',
          phone: response.data.phone || '',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        setErrorMessage('Failed to fetch user data');
      }
    };
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');
      
      const { email, ...updateData } = formData;
      const response = await axios.put(
        'https://etsyhelper.onrender.com/user',
        updateData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      setUser(response.data);
      setSuccessMessage('Profile updated successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage('Failed to update profile');
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setErrorMessage('New passwords do not match');
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');
      
      await axios.put(
        'https://etsyhelper.onrender.com/user/password',
        {
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      setSuccessMessage('Password updated successfully');
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating password:', error);
      setErrorMessage('Failed to update password');
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-800 mb-2">Account Settings</h1>
        <p className="text-gray-500">Manage your account information and change your password</p>
      </div>

      {successMessage && (
        <div className="mb-6 p-4 rounded-xl bg-green-50 text-green-700 flex items-center">
          <FaCheck className="mr-2" />
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="mb-6 p-4 rounded-xl bg-red-50 text-red-700 flex items-center">
          <FaExclamationCircle className="mr-2" />
          {errorMessage}
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-white p-8 rounded-2xl shadow-lg">
          <div className="flex items-center mb-6">
            <div className="p-3 bg-purple-100 rounded-xl mr-4">
              <FaUser className="text-purple-500 text-xl" />
            </div>
            <div>
              <h2 className="text-xl font-bold text-gray-800">Personal Information</h2>
              <p className="text-gray-500 text-sm">Update your basic profile information</p>
            </div>
          </div>
          
          <ProfileForm 
            formData={formData}
            onChange={handleChange}
            onSubmit={handleSubmit}
            loading={loading}
          />
        </div>

        <div className="bg-white p-8 rounded-2xl shadow-lg">
          <div className="flex items-center mb-6">
            <div className="p-3 bg-purple-100 rounded-xl mr-4">
              <FaLock className="text-purple-500 text-xl" />
            </div>
            <div>
              <h2 className="text-xl font-bold text-gray-800">Security</h2>
              <p className="text-gray-500 text-sm">Change your password</p>
            </div>
          </div>

          <PasswordForm 
            passwordData={passwordData}
            onChange={handlePasswordChange}
            onSubmit={handlePasswordSubmit}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;