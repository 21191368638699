import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader2, CheckCircle, XCircle } from 'lucide-react';

const AuthCallback = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('loading'); // loading, success, error

    useEffect(() => {
        const getToken = async (code) => {
            const clientId = process.env.REACT_APP_ETSY_CLIENT_ID;
            const redirectUri = process.env.REACT_APP_ETSY_REDIRECT_URI;
            const codeVerifier = localStorage.getItem('codeVerifier');
            
            const query = new URLSearchParams(window.location.search);
            const returnedState = query.get('state');
            const savedState = localStorage.getItem('oauthState');
            const token = localStorage.getItem('token');
            const storeId = localStorage.getItem('storeId');

            if (returnedState !== savedState) {
                setStatus('error');
                toast.error('State parametresi eşleşmedi.');
                return;
            }

            try {
                const response = await fetch('https://etsyhelper.onrender.com/exchange-token', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        client_id: clientId,
                        redirect_uri: redirectUri,
                        code: code,
                        code_verifier: codeVerifier,
                        store_id: storeId
                    }),
                });
        
                const data = await response.json();
                if (response.ok) {
                    console.log('Access Token:', data.access_token);
                    localStorage.setItem('etsyAccessToken', data.access_token);
                    setStatus('success');
                    setTimeout(() => {
                        navigate('/my-account/stores');
                    }, 1500);
                } else {
                    console.error('Error exchanging token:', data);
                    setStatus('error');
                    toast.error('Token alma işlemi sırasında hata oluştu.');
                }
            } 
            catch (error) {
                console.error('Error:', error);
                setStatus('error');
                toast.error('Token alma işlemi sırasında hata oluştu.');
            }
        };

        const query = new URLSearchParams(window.location.search);
        const code = query.get('code');
        if (code) {
            getToken(code);
        } else {
            setStatus('error');
            toast.error('Yetkilendirme kodu bulunamadı.');
        }
    }, [navigate]);

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full space-y-6">
                {status === 'loading' && (
                    <div className="text-center space-y-4">
                        <Loader2 className="w-16 h-16 text-blue-500 animate-spin mx-auto" />
                        <h2 className="text-xl font-semibold text-gray-800">
                            Yetkilendirme İşlemi Devam Ediyor
                        </h2>
                        <p className="text-gray-500">
                            Lütfen bekleyin, işleminiz tamamlanıyor...
                        </p>
                    </div>
                )}

                {status === 'success' && (
                    <div className="text-center space-y-4">
                        <CheckCircle className="w-16 h-16 text-green-500 mx-auto" />
                        <h2 className="text-xl font-semibold text-gray-800">
                            Yetkilendirme Başarılı!
                        </h2>
                        <p className="text-gray-500">
                            Yönlendiriliyorsunuz...
                        </p>
                    </div>
                )}

                {status === 'error' && (
                    <div className="text-center space-y-4">
                        <XCircle className="w-16 h-16 text-red-500 mx-auto" />
                        <h2 className="text-xl font-semibold text-gray-800">
                            Yetkilendirme Hatası
                        </h2>
                        <p className="text-gray-500">
                            İşlem sırasında bir hata oluştu. Lütfen tekrar deneyin.
                        </p>
                        <button 
                            onClick={() => window.location.reload()}
                            className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
                        >
                            Tekrar Dene
                        </button>
                    </div>
                )}

                <div className="mt-6 text-center">
                    <div className="inline-flex items-center space-x-2">
                        <div className="w-2 h-2 rounded-full bg-gray-300 animate-pulse"></div>
                        <div className="w-2 h-2 rounded-full bg-gray-300 animate-pulse delay-75"></div>
                        <div className="w-2 h-2 rounded-full bg-gray-300 animate-pulse delay-150"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthCallback;