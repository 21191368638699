import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaHome, FaCheck, FaHeadset, FaUser, FaFile, FaStore, FaSignOutAlt, FaFilter, FaImage } from 'react-icons/fa';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { FaArrowsRotate, FaBoxesStacked, FaListCheck } from 'react-icons/fa6';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const MySidebar = ({ sidebarOpen, toggleSidebar }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });

  const navigate = useNavigate();
  const { logout } = useAuth();

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://etsyhelper.onrender.com/user', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Kullanıcı verisi alınırken hata oluştu:', error);
      }
    };
    fetchUserData();
  }, []);

  const NavItem = ({ to, icon: Icon, children }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center px-4 py-2.5 rounded-xl transition-all duration-200 ${
          isActive 
            ? 'bg-purple-600 text-white shadow-lg shadow-purple-200' 
            : 'text-gray-600 hover:bg-purple-50'
        }`
      }
      onClick={toggleSidebar}
    >
      <Icon className={`w-5 h-5 mr-3 transition-all duration-200`} />
      <span className="text-sm font-medium">{children}</span>
    </NavLink>
  );

  return (
    <div 
      className={`fixed top-0 left-0 h-screen w-72 bg-white shadow-2xl p-6 flex flex-col transform transition-transform duration-300 ease-in-out ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      {/* Logo */}
      <div className="flex items-center justify-center mb-8">
        <h1 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-purple-400 bg-clip-text text-transparent">
          Canvas Helper
        </h1>
      </div>

      {/* Main Navigation */}
      <nav className="flex-1 space-y-6">
        {/* Ana Sayfa */}
        <div>
          <NavItem to="/" icon={FaHome}>Ana Sayfa</NavItem>
        </div>

        {/* Ürünler Bölümü */}
        <div className="space-y-2">
          <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
            Ürünler
          </h2>
          <div className="space-y-1">
            <NavItem to="/active-products" icon={FaCheck}>Aktif Ürünler</NavItem>
            <NavItem to="/prepared-listings" icon={FaCheck}>Hazır Ürünler</NavItem>
            <NavItem to="/all-products" icon={FaListCheck}>Incomplete Products</NavItem>
            <NavItem to="/bulk-upload" icon={FaBoxesStacked}>Toplu Ürün Ekle</NavItem>
          </div>
        </div>

        {/* Destek */}
        <div>
          <NavItem to="/studio" icon={FaImage}>Mockup Creator</NavItem>
          <NavItem to="/support" icon={FaHeadset}>Destek</NavItem>
        </div>

        {/* Hesap Ayarları */}
        <div className="space-y-2">
          <h2 className="px-4 text-xs font-semibold text-gray-400 uppercase tracking-wider">
            Hesap Ayarları
          </h2>
          <div className="space-y-1">
            <NavItem to="/my-account/account-details" icon={FaUser}>Hesap Detayları</NavItem>
            <NavItem to="/my-account/subscription" icon={FaArrowsRotate}>Abonelik Yönetimi</NavItem>
            <NavItem to="/variation-profiles" icon={FaFilter}>Varyasyon Profilleri</NavItem>
            <NavItem to="/my-account/description-generator" icon={FaFile}>Açıklama Oluşturucu</NavItem>
            <NavItem to="/my-account/stores" icon={FaStore}>Mağazalarım</NavItem>
          </div>
        </div>
      </nav>

      {/* User Profile Section */}
      <div className="mt-6 border-t border-gray-100 pt-6">
        <div 
          className="px-4 py-3 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
          onClick={toggleUserMenu}
        >
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <h4 className="text-sm font-semibold text-gray-700">
                {`${userData.first_name} ${userData.last_name}`}
              </h4>
              <p className="text-xs text-gray-500 truncate">{userData.email}</p>
            </div>
            {isUserMenuOpen ? (
              <FiChevronUp className="w-5 h-5 text-gray-400" />
            ) : (
              <FiChevronDown className="w-5 h-5 text-gray-400" />
            )}
          </div>
        </div>

        {isUserMenuOpen && (
          <div className="mt-2 space-y-1">
            <NavLink
              to="/my-account/account-details"
              className="flex items-center px-4 py-2.5 text-sm text-gray-600 rounded-xl hover:bg-purple-50"
              onClick={toggleSidebar}
            >
              <FaUser className="w-4 h-4 mr-3" />
              Hesap Detayları
            </NavLink>
            <button
              onClick={handleLogout}
              className="w-full flex items-center px-4 py-2.5 text-sm text-gray-600 rounded-xl hover:bg-purple-50"
            >
              <FaSignOutAlt className="w-4 h-4 mr-3" />
              Oturumu Kapat
            </button>
          </div>
        )}

        <div className="mt-6 px-4 py-3">
          <p className="text-xs text-gray-400">Canvas Helper v1.1</p>
        </div>
      </div>
    </div>
  );
};

export default MySidebar;