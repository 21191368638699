import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function ShippingProfiles({ storeId }) {
    const [shippingProfiles, setShippingProfiles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchShippingProfiles();
    }, [storeId]);

    const fetchShippingProfiles = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/shipping-profiles`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                setShippingProfiles(response.data.shipping_profiles);
                toast.success('Shipping profiles fetched successfully!');
            } else {
                toast.error('Failed to fetch shipping profiles.');
            }
        } catch (error) {
            console.error('Error fetching shipping profiles:', error);
            toast.error('Error fetching shipping profiles.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Shipping Profiles</h2>
            {loading ? (
                <p>Loading shipping profiles...</p>
            ) : (
                <ul className="list-disc pl-5">
                    {shippingProfiles.length > 0 ? (
                        shippingProfiles.map((profile) => (
                            <li key={profile.shipping_profile_id}>
                                <div className="bg-white p-4 rounded-lg shadow-md mb-4">
                                    <p><strong>Title:</strong> {profile.title}</p>
                                    <p><strong>Min Processing Days:</strong> {profile.min_processing_days || 'N/A'}</p>
                                    <p><strong>Max Processing Days:</strong> {profile.max_processing_days || 'N/A'}</p>
                                </div>
                            </li>
                        ))
                    ) : (
                        <p>No shipping profiles available for this store.</p>
                    )}
                </ul>
            )}
        </div>
    );
}

export default ShippingProfiles;
