import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { quantum } from 'ldrs'; // uiball yükleme animasyonu
quantum.register();

const ProductEditModal = ({ productId, showModal, setShowModal, onProductUpdate }) => {
  const { user } = useAuth();
  const [product, setProduct] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [baseImage, setBaseImage] = useState('');
  const [baseImageFile, setBaseImageFile] = useState(null);
  const [variationProfileId, setVariationProfileId] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [variationProfiles, setVariationProfiles] = useState([]);
  const [mockupImages, setMockupImages] = useState([]);
  const [isGeneratingMockup, setIsGeneratingMockup] = useState(false);
  const [userMockupFile, setUserMockupFile] = useState(null);
  const [userMockupPreview, setUserMockupPreview] = useState('');
  const [sections, setSections] = useState([]);
  const [isUserMockupDeleted, setIsUserMockupDeleted] = useState(false);

  // Reset all states when modal is closed or productId changes
  useEffect(() => {
    const resetStates = () => {
      setProduct(null);
      setName('');
      setDescription('');
      setTags('');
      setBaseImage('');
      setBaseImageFile(null);
      setVariationProfileId('');
      setError('');
      setMockupImages([]);
      setUserMockupFile(null);
      setUserMockupPreview('');
      setIsUserMockupDeleted(false);
    };

    if (!showModal) {
      resetStates();
    }
  }, [showModal, productId]);

  useEffect(() => {
    if (productId && showModal) {
      const fetchProduct = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) throw new Error('No token found');
          
          const response = await axios.get(`https://etsyhelper.onrender.com/products/${productId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const { name, description, tags, base_image, variation_profile_id, images, user_mockups, section_id } = response.data;
          
          setProduct(response.data);
          setName(name || '');
          setDescription(description || '');
          setTags(tags || '');
          setBaseImage(base_image || '');
          setVariationProfileId(variation_profile_id || '');
          setMockupImages(images ? images.split(',') : []);
          
          if (user_mockups) {
            setUserMockupPreview(user_mockups);
          }

          if (section_id) {
            setProduct((prev) => ({ ...prev, section_id }));
          }
        } catch (error) {
          console.error('Error fetching product:', error);
          setError('Failed to fetch product');
        } finally {
          setLoading(false);
        }
      };
      
      fetchProduct();
      fetchVariationProfiles();
    }
  }, [productId, showModal]);

  const fetchVariationProfiles = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get('https://etsyhelper.onrender.com/variation_profiles', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVariationProfiles(response.data);
    } catch (error) {
      console.error('Error fetching variation profiles:', error);
    }
  };

    // store_sections tablosundaki sectionları fetch etmek için yeni useEffect hook
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        // store_sections tablosundan sectionları çekiyoruz
        const response = await axios.get('https://etsyhelper.onrender.com/store_sections', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSections(response.data); // Gelen sectionları set ediyoruz
      } catch (error) {
        console.error('Error fetching store sections:', error);
      }
    };

    fetchSections();
  }, []);

  // Silme işlemi için basit bir fonksiyon
  const handleDeleteUserMockup = () => {
    setUserMockupFile(null);
    setUserMockupPreview('');
    setIsUserMockupDeleted(true);
    toast.success('Custom mockup will be removed when you save');
  };
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBaseImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setBaseImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

// Dropzone konfigürasyonu
const { getRootProps, getInputProps } = useDropzone({
  accept: {
    'image/*': ['.jpeg', '.jpg', '.png', '.gif']
  },
  maxFiles: 1,
  onDrop: (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setUserMockupFile(file);
      // URL.createObjectURL kullanarak önizleme URL'i oluştur
      const previewUrl = URL.createObjectURL(file);
      setUserMockupPreview(previewUrl);
      setIsUserMockupDeleted(false); // Yeni dosya yüklendiğinde deleted flag'i reset
    }
  }
});

useEffect(() => {
  return () => {
    if (userMockupPreview && userMockupPreview.startsWith('blob:')) {
      URL.revokeObjectURL(userMockupPreview);
    }
  };
}, [userMockupPreview]);

const handleSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();

  formData.append('name', name);
  formData.append('description', description);
  formData.append('tags', tags);
  formData.append('variation_profile_id', variationProfileId);
  formData.append('section_id', product?.section_id || '');

  if (baseImageFile) {
    formData.append('base_image', baseImageFile);
  }

  // Eğer mockup silinmişse null_user_mockups flag'ini true olarak gönder
  if (isUserMockupDeleted) {
    formData.append('null_user_mockups', 'true');
  } else if (userMockupFile) {
    formData.append('user_mockups', userMockupFile);
  }

  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }
    await axios.put(`https://etsyhelper.onrender.com/products/${productId}`, formData, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    toast.success('Product updated successfully');
    onProductUpdate();
    setShowModal(false);
  } catch (error) {
    console.error('Error updating product:', error);
    setError('Failed to update product');
  }
};


  const handleGenerateMockup = async () => {
    setIsGeneratingMockup(true);
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (mockupImages.length > 0) {
        await axios.post('https://etsyhelper.onrender.com/delete_mockups', { product_id: productId }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      await axios.post('https://etsyhelper.onrender.com/generate_mockup', { product_id: productId }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Mockup generated successfully');
      onProductUpdate();
    } catch (error) {
      console.error('Error generating mockup:', error);
      toast.error('Failed to generate mockup');
    } finally {
      setIsGeneratingMockup(false);
      setLoading(false);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h1 className="text-2xl font-bold text-center mb-4">Edit Product</h1>
            {loading && (
              <div className="absolute inset-0 bg-gray-100 bg-opacity-50 flex justify-center items-center z-50">
                <l-quantum size="45" speed="1.75" color="black"></l-quantum>
              </div>
            )}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                    Tags
                  </label>
                  <input
                    type="text"
                    name="tags"
                    id="tags"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="base_image" className="block text-sm font-medium text-gray-700">
                    Base Image
                  </label>
                  <div className="flex items-center">
                    <img src={baseImage} alt="Base" className="w-24 h-24 object-contain rounded-md mr-4" />
                    <input
                      type="file"
                      name="base_image"
                      id="base_image"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                    <label
                      htmlFor="base_image"
                      className="cursor-pointer bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                    >
                      Change
                    </label>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="variation_profile_id" className="block text-sm font-medium text-gray-700">
                    Variation Profile
                  </label>
                  <select
                    name="variation_profile_id"
                    id="variation_profile_id"
                    value={variationProfileId}  // Güncellendi
                    onChange={(e) => setVariationProfileId(e.target.value)}  // Güncellendi
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  >
                    <option value="" disabled>Select a profile</option>
                    {variationProfiles.map((profile) => (
                      <option key={profile.id} value={profile.id}>
                        {profile.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="section" className="block text-sm font-medium text-gray-700">
                    Select Section
                  </label>
                  <select
                    name="section"
                    id="section"
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                    value={product?.section_id || ''}  // Eğer product section içeriyorsa seçili gösteriyoruz
                    onChange={(e) => setProduct({ ...product, section_id: e.target.value })}  // Section'ı state'e kaydediyoruz
                  >
                    <option value="" disabled>Select a section</option>
                    {sections.map((section) => (
                      <option key={section.id} value={section.id}>
                        {section.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Custom Mockup
                  </label>
                  
                  <div className="w-full">
                    {!isUserMockupDeleted && (userMockupPreview || product?.user_mockups) ? (
                      // Preview Container
                      <div className="relative w-full h-64 bg-gray-50 rounded-lg overflow-hidden border-2 border-gray-200">
                        <img 
                          src={userMockupPreview || product?.user_mockups} 
                          alt="Mockup Preview" 
                          className="w-full h-full object-contain"
                        />
                        <div className="absolute top-0 right-0 p-2">
                          <button
                            type="button"
                            onClick={handleDeleteUserMockup}
                            className="bg-red-500 hover:bg-red-600 text-white rounded-full p-2 shadow-lg transition-all duration-300"
                            title="Remove mockup"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : (
                      // Dropzone
                      <div 
                        {...getRootProps()} 
                        className="cursor-pointer border-2 border-dashed border-gray-300 rounded-lg transition-all duration-300 hover:border-purple-400 hover:bg-purple-50"
                      >
                        <div className="p-8">
                          <input {...getInputProps()} />
                          <div className="text-center">
                            <svg 
                              className="mx-auto h-12 w-12 text-gray-400" 
                              stroke="currentColor" 
                              fill="none" 
                              viewBox="0 0 48 48" 
                              aria-hidden="true"
                            >
                              <path 
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" 
                                strokeWidth={2} 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                              />
                            </svg>
                            <div className="mt-4 flex text-sm text-gray-600 justify-center">
                              <label className="relative cursor-pointer rounded-md font-medium text-purple-600 hover:text-purple-500">
                                <span>Upload a mockup</span>
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500 mt-2">PNG, JPG, GIF up to 10MB</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Mockup Images</label>
                  <div className="grid grid-cols-3 gap-4 mt-2">
                    {mockupImages.map((image, index) => (
                      <img key={index} src={image} alt={`Mockup ${index + 1}`} className="w-24 h-24 object-contain rounded-md" />
                    ))}
                  </div>
                </div>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={handleGenerateMockup}
                    disabled={isGeneratingMockup}
                  >
                    {isGeneratingMockup ? 'Generating...' : mockupImages.length > 0 ? 'Regenerate Mockup' : 'Generate Mockup'}
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEditModal;
