import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Stage, Layer, Image, Transformer, Line } from 'react-konva';
import { Trash2, Image as ImageIcon, Layout, Save } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import { FilterFrames } from '@mui/icons-material';

const MockupCreator = ({ token }) => {
  const [aspectRatio, setAspectRatio] = useState('square');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [frames, setFrames] = useState([]); // Tüm çerçeveler
  const [filteredFrames, setFilteredFrames] = useState([]); // Filtrelenmiş çerçeveler
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [guides, setGuides] = useState({ vertical: null, horizontal: null });
  const [selectedProfile, setSelectedProfile] = useState('1'); // Seçilen profil

  const imageRefs = useRef(new Map());
  const transformerRef = useRef(null);

  // Gerçek canvas boyutları
  const realSize = {
    square: { width: 3000, height: 3000 },
    landscape: { width: 4500, height: 3000 }
  };

  // Görüntüleme boyutları (ekranda görünecek boyut)
  const displaySize = {
    square: { width: 600, height: 600 },
    landscape: { width: 900, height: 600 }
  };

  // Ölçek faktörünü hesapla
  const getScale = (format) => ({
    x: displaySize[format].width / realSize[format].width,
    y: displaySize[format].height / realSize[format].height
  });

  const getCoverImageProps = (img, format) => {
    const canvasWidth = realSize[format].width;
    const canvasHeight = realSize[format].height;

    const scale = Math.max(
      canvasWidth / img.width,
      canvasHeight / img.height
    );
    const width = img.width * scale;
    const height = img.height * scale;
    const x = (canvasWidth - width) / 2;
    const y = (canvasHeight - height) / 2;

    return { x, y, width, height, draggable: true };
  };

  const resetForm = () => {
    setBackgroundImage(null);
    setElements([]);
    setSelectedElement(null);
    setGuides({ vertical: null, horizontal: null });
    // Eğer profil seçimini de sıfırlamak istiyorsanız:
    setSelectedProfile('');
  };

  useEffect(() => {
    const fetchFrames = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://etsyhelper.onrender.com/studio_assets', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      
        if (response.data.frames) {
          setFrames(response.data.frames);
          const initialFilteredFrames = response.data.frames.filter(
            frame => frame.variation_profile_id === 1
          );
          setFilteredFrames(initialFilteredFrames);
        }
      } catch (error) {
        console.error("Çerçeve verileri alınamadı:", error);
      }
    };

    fetchFrames();
  }, [token]);

  // Select box seçim işlemi
  const handleProfileSelect = (e) => {
    const profileId = parseInt(e.target.value);
    setSelectedProfile(profileId);

    // Seçilen profile göre çerçeveleri filtrele
    const newFilteredFrames = frames.filter(frame => frame.variation_profile_id === profileId);
    setFilteredFrames(newFilteredFrames);
  };

  // Transformer için useEffect
  useEffect(() => {
    if (selectedElement) {
      const node = imageRefs.current.get(selectedElement);
      if (node) {
        transformerRef.current.nodes([node]);
        transformerRef.current.getLayer().batchDraw();
      }
    }
  }, [selectedElement]);

  // Element değişikliklerini izleyen useEffect
  useEffect(() => {
    elements.forEach(el => {
      const node = imageRefs.current.get(el.id);
      if (node) {
        node.scaleX(1);
        node.scaleY(1);
      }
    });
  }, [elements]);

  const handleBackgroundUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new window.Image();
        img.src = reader.result;
        img.onload = () => {
          setBackgroundImage({
            image: img,
            ...getCoverImageProps(img, aspectRatio)
          });
          setSelectedElement('background');
        };
      };
      reader.readAsDataURL(file);
    }
  };

  
  const handleTransform = (e) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    
    // Reset scale to 1 and adjust width/height instead
    node.scaleX(1);
    node.scaleY(1);
    
    const elementIndex = elements.findIndex(el => el.id === selectedElement);
    if (elementIndex !== -1) {
      const newElements = [...elements];
      newElements[elementIndex] = {
        ...newElements[elementIndex],
        width: node.width() * scaleX,
        height: node.height() * scaleY,
        x: node.x(),
        y: node.y()
      };
      setElements(newElements);
    }
  };

  const addFrameToCanvas = (frame) => {
    if (elements.some((el) => el.type === 'frame')) {
      toast.error("Sadece bir tane çerçeve ekleyebilirsiniz.");
      return;
    }
  
    const frameImage = new window.Image();
    frameImage.src = frame.image_url;
    frameImage.onload = () => {
      const newFrame = {
        id: `frame-${elements.length}`,
        image: frameImage,
        name: frame.name, // name bilgisini ekliyoruz
        x: realSize[aspectRatio].width / 4,
        y: realSize[aspectRatio].height / 4,
        width: frameImage.width,
        height: frameImage.height,
        originalWidth: frameImage.width,
        originalHeight: frameImage.height,
        draggable: true,
        type: 'frame',
        frameId: frame.id, // veritabanı id'sini de tutabiliriz
        isNoFrame: frame.name.toLowerCase() === 'no frame' // Sadece flag'i set et
      };
      setElements([...elements, newFrame]);
      setSelectedElement(newFrame.id);
    };
  };

  const handleFormatChange = (format) => {
    setAspectRatio(format);

    // format seçimine göre çerçeveleri filtrele
    const filteredFrames = frames.filter(frame => frame.aspectRatio === format);
    setFrames(filteredFrames);

    if (backgroundImage) {
      setBackgroundImage({
        ...backgroundImage,
        ...getCoverImageProps(backgroundImage.image, format)
      });
    }
};

const handleDragMove = (e) => {
  const imageNode = e.target;
  const canvasWidth = realSize[aspectRatio].width;
  const canvasHeight = realSize[aspectRatio].height;

  const elementCenterX = imageNode.x() + imageNode.width() / 2;
  const elementCenterY = imageNode.y() + imageNode.height() / 2;
  const canvasCenterX = canvasWidth / 2;
  const canvasCenterY = canvasHeight / 2;
  const snapThreshold = 50; // Gerçek boyutta daha büyük snap threshold
  const isNearCenterX = Math.abs(elementCenterX - canvasCenterX) < snapThreshold;
  const isNearCenterY = Math.abs(elementCenterY - canvasCenterY) < snapThreshold;

  if (selectedElement === 'background') {
    const minX = Math.min(0, canvasWidth - imageNode.width());
    const maxX = 0;
    const minY = Math.min(0, canvasHeight - imageNode.height());
    const maxY = 0;

    if (imageNode.x() > maxX) imageNode.x(maxX);
    if (imageNode.x() < minX) imageNode.x(minX);
    if (imageNode.y() > maxY) imageNode.y(maxY);
    if (imageNode.y() < minY) imageNode.y(minY);
  }

  setGuides({
    vertical: isNearCenterX ? canvasCenterX : null,
    horizontal: isNearCenterY ? canvasCenterY : null,
  });

  if (isNearCenterX) {
    imageNode.x(canvasCenterX - imageNode.width() / 2);
  }
  if (isNearCenterY) {
    imageNode.y(canvasCenterY - imageNode.height() / 2);
  }
};
  
const handleDragEnd = (e) => {
  setGuides({ vertical: null, horizontal: null });

  // Sürükleme sonrası konum güncelleme
  const node = e.target;
  if (selectedElement === 'background') {
    setBackgroundImage(prev => ({
      ...prev,
      x: node.x(),
      y: node.y()
    }));
  } else {
    setElements(prevElements =>
      prevElements.map(el =>
        el.id === selectedElement
          ? {
              ...el,
              x: node.x(),
              y: node.y()
            }
          : el
      )
    );
  }
};

  const handleDelete = () => {
    if (selectedElement) {
      setElements(elements.filter((el) => el.id !== selectedElement));
      setSelectedElement(null);
    }
  };

  // Resmi blob'a çevirmek için yardımcı fonksiyon
  const getBackgroundImageOnly = () => {
    return new Promise((resolve) => {
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = realSize[aspectRatio].width;
      tempCanvas.height = realSize[aspectRatio].height;
      const tempCtx = tempCanvas.getContext('2d');

      if (backgroundImage && backgroundImage.image) {
        tempCtx.drawImage(
          backgroundImage.image,
          backgroundImage.x,
          backgroundImage.y,
          backgroundImage.width,
          backgroundImage.height
        );
      }

      tempCanvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.95);
    });
  };

// Ana kaydetme fonksiyonu
const handleSave = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!backgroundImage) {
      toast.error('Lütfen bir arka plan resmi seçin');
      return;
    }

    if (!selectedProfile) {
      toast.error('Lütfen bir varyasyon profili seçin');
      return;
    }

    const frame = elements.find(el => el.type === 'frame');
    if (!frame) {
      toast.error('Lütfen bir çerçeve seçin veya "No Frame" seçeneğini kullanın');
      return;
    }

    setIsLoading(true);

    // Arka plan resmini al ve blob'a çevir
    const roomImageBlob = await getBackgroundImageOnly();

    // Form verilerini oluştur
    const formData = new FormData();
    formData.append('profile_id', selectedProfile);
    formData.append('room_image', roomImageBlob, 'room.jpg');
    formData.append('frame_name', frame.name);

    if (frame) {
      formData.append('no_frame', frame.isNoFrame.toString());
    
    // Frame URL'i sadece no_frame değilse ekle
    if (!frame.isNoFrame) {
      formData.append('frame_url', frame.image.src);
    }
      // Frame'in pozisyonlarını ekle
      formData.append('left_offset', Math.round(frame.x).toString());
      formData.append('top_offset', Math.round(frame.y).toString());
      formData.append('frame_width', Math.round(frame.width).toString());
      formData.append('frame_height', Math.round(frame.height).toString());
    } else {
      formData.append('no_frame', 'true');
    }

    // API'ye gönder
    const response = await axios.post(
      'https://etsyhelper.onrender.com/create_mockup',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data.success) {
      toast.success('Mockup başarıyla kaydedildi!');
      resetForm();
      // İsteğe bağlı: Form'u resetle veya yönlendirme yap
    }

  } catch (error) {
    console.error('Kayıt hatası:', error);
    toast.error(
      error.response?.data?.message || 
      'Mockup kaydedilirken bir hata oluştu'
    );
  } finally {
    setIsLoading(false);
  }
};

// Loading state için UI
const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex h-screen bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-white">
      {/* Araç Çubuğu */}
      <div className="w-72 bg-white dark:bg-gray-800 shadow-lg p-4 space-y-6 overflow-y-auto">
        <div className="border-b pb-4">
          <h2 className="text-xl font-semibold text-purple-600 flex items-center gap-2">
            <Layout className="w-5 h-5" />
            Mockup Oluşturucu
          </h2>
        </div>
        {/* Varyasyon Profili Seçimi */}
        <div className="space-y-3">
          <h3 className="text-sm font-semibold">Varyasyon Profili</h3>
          <select
            value={selectedProfile} 
            onChange={handleProfileSelect}
            className="block w-full text-sm rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-gray-300"
          >
            <option value="">Bir profil seçin</option>
            {/* Örnek profiller */}
            <option value="1">Dikey</option>
            <option value="2">Kare</option>
            <option value="3">Yatay</option>
          </select>
        </div>

        {/* Arka Plan Yükleme */}
        <div className="space-y-3">
          <h3 className="text-sm font-semibold flex items-center gap-2">
            <ImageIcon className="w-4 h-4" />
            Arka Plan
          </h3>
          <div className="relative">
            <input
              type="file"
              onChange={handleBackgroundUpload}
              accept="image/*"
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0 file:text-sm file:font-semibold
                file:bg-purple-50 file:text-purple-700 hover:file:bg-purple-100
                dark:file:bg-purple-900 dark:file:text-purple-300"
            />
          </div>
        </div>

        {/* Format Seçimi */}
        <div className="space-y-3">
          <h3 className="text-sm font-semibold">Format</h3>
          <div className="grid grid-cols-2 gap-2">
            <button
              onClick={() => handleFormatChange('square')}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors
                ${aspectRatio === 'square'
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600'
                }`}
            >
              Kare
            </button>
            <button
              onClick={() => handleFormatChange('landscape')}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors
                ${aspectRatio === 'landscape'
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600'
                }`}
            >
              Yatay
            </button>
          </div>
        </div>

        {/* Çerçeveler */}
        <div className="space-y-3">
          <h3 className="text-sm font-semibold">Çerçeveler</h3>
          <div className="grid grid-cols-2 gap-2 max-h-64 overflow-y-auto p-2 bg-gray-50 dark:bg-gray-700 rounded-lg">
            {filteredFrames.map((frame) => (
              <div
                key={frame.id}
                onClick={() => addFrameToCanvas(frame)} // Tüm frame objesini gönderiyoruz
                className="cursor-pointer group relative bg-white dark:bg-gray-800 rounded-lg p-2 
                  hover:bg-purple-50 dark:hover:bg-purple-900 transition-colors"
              >
                <div className="aspect-square relative">
                  <img 
                    src={frame.image_url} 
                    alt={frame.name}
                    className="w-full h-full object-contain rounded-md"
                  />
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/5 
                    transition-colors rounded-lg" />
                </div>
                <div className="mt-2 px-1">
                  <p className="text-xs text-center truncate text-gray-600 dark:text-gray-300" 
                    title={frame.name}>
                    {frame.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Eylemler */}
        <div className="space-y-3">
          <h3 className="text-sm font-semibold">Eylemler</h3>
          <div className="space-y-2">
            <button
              onClick={handleDelete}
              disabled={!selectedElement}
              className={`w-full px-4 py-2 text-sm font-medium rounded-lg flex items-center justify-center gap-2
                ${selectedElement 
                  ? 'bg-red-600 hover:bg-red-700 text-white' 
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700'
                }`}
            >
              <Trash2 className="w-4 h-4" />
              Seçili Öğeyi Sil
            </button>

            {/* Kaydet butonu */}
            <button
              onClick={handleSave}
              disabled={isLoading || !backgroundImage || !selectedProfile || !elements.some(el => el.type === 'frame')}
              className={`w-full px-4 py-2 text-sm font-medium rounded-lg flex items-center justify-center gap-2 transition-colors
                ${isLoading || !backgroundImage || !selectedProfile || !elements.some(el => el.type === 'frame')
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-700 dark:text-gray-400'
                  : 'bg-purple-600 hover:bg-purple-700 text-white'
                }`}
            >
              {isLoading ? (
                <>
                  <span className="animate-spin">⚪</span>
                  Kaydediliyor...
                </>
              ) : (
                <>
                  <Save className="w-4 h-4" />
                  Kaydet
                </>
              )}
            </button>
          </div>
        </div>

        {/* Seçili Öğe Bilgisi */}
        {selectedElement && (
          <div className="space-y-3">
            <h3 className="text-sm font-semibold">Seçili Öğe</h3>
            <div className="p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-sm">
                ID: {selectedElement}
              </p>
              {elements.find(el => el.id === selectedElement)?.name && (
                <p className="text-sm mt-1">
                  İsim: {elements.find(el => el.id === selectedElement).name}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Canvas Alanı */}
      <div className="flex-1 flex flex-col items-center justify-center p-6 bg-gray-50 dark:bg-gray-800">
        <Stage
           width={displaySize[aspectRatio].width}
           height={displaySize[aspectRatio].height}
           scale={getScale(aspectRatio)}
           className="shadow-lg bg-white dark:bg-gray-700 rounded-lg"
        >
          <Layer>
            {backgroundImage && (
              <Image
                ref={node => imageRefs.current.set('background', node)}
                {...backgroundImage}
                draggable
                onDragMove={handleDragMove}
                onDragEnd={handleDragEnd}
                onClick={() => setSelectedElement('background')}
                onTransformEnd={handleTransform}
              />
            )}
            
            {elements.map((el) => (
              <Image
                key={el.id}
                ref={node => imageRefs.current.set(el.id, node)}
                image={el.image}
                x={el.x}
                y={el.y}
                width={el.width}
                height={el.height}
                draggable
                onDragMove={handleDragMove}
                onDragEnd={handleDragEnd}
                onClick={() => setSelectedElement(el.id)}
                onTransformEnd={handleTransform}
              />
            ))}

            {selectedElement && (
              <Transformer
                ref={transformerRef}
                boundBoxFunc={(oldBox, newBox) => {
                  const selectedNode = elements.find(el => el.id === selectedElement);
                  if (selectedNode) {
                    const aspectRatio = selectedNode.originalWidth / selectedNode.originalHeight;
                    newBox.width = Math.max(30, newBox.width);
                    newBox.height = newBox.width / aspectRatio;
                  }
                  return newBox;
                }}
                keepRatio={true}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              />
            )}
            {/* Kılavuz Çizgileri */}
            {guides.vertical && (
              <Line
                points={[guides.vertical, 0, guides.vertical, displaySize[aspectRatio].height]}
                stroke="blue"
                strokeWidth={1}
                dash={[4, 6]}
              />
            )}
            {guides.horizontal && (
              <Line
                points={[0, guides.horizontal, displaySize[aspectRatio].width, guides.horizontal]}
                stroke="blue"
                strokeWidth={1}
                dash={[4, 6]}
              />
            )}
          </Layer>
        </Stage>
      </div>
       {/* Toast Container'ı ana div'in en üstüne ekleyin */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default MockupCreator;

