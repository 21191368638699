import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

// Static list of art subjects
const art_subjects = [
    { id: 1, name: 'Abstract & geometric' },
    { id: 2, name: 'Animal' },
    { id: 3, name: 'Anime & cartoon' },
    { id: 4, name: 'Architecture & cityscape' },
    { id: 5, name: 'Beach & tropical' },
    { id: 6, name: 'Bollywood' },
    { id: 7, name: 'Comics & manga' },
    { id: 8, name: 'Fantasy & Sci Fi' },
    { id: 9, name: 'Fashion' },
    { id: 10, name: 'Flowers' },
    { id: 11, name: 'Food & drink' },
    { id: 12, name: 'Geography & locale' },
    { id: 13, name: 'Horror & gothic' },
    { id: 14, name: 'Humorous saying' },
    { id: 15, name: 'Inspirational saying' },
    { id: 16, name: 'Landscape & scenery' },
    { id: 17, name: 'LGBTQ pride' },
    { id: 18, name: 'Love & friendship' },
    { id: 19, name: 'Military' },
    { id: 20, name: 'Movie' },
    { id: 21, name: 'Music' },
    { id: 22, name: 'Nautical' },
    { id: 23, name: 'Nudes' },
    { id: 24, name: 'Patriotic & flags' },
    { id: 25, name: 'People & portrait' },
    { id: 26, name: 'Pet portrait' },
    { id: 27, name: 'Phrase & saying' },
    { id: 28, name: 'Plants & trees' },
    { id: 29, name: 'Religious' },
    { id: 30, name: 'Science & tech' },
    { id: 31, name: 'Sports & fitness' },
    { id: 32, name: 'Stars & celestial' },
    { id: 33, name: 'Steampunk' },
    { id: 34, name: 'Superhero' },
    { id: 35, name: 'Travel & transportation' },
    { id: 36, name: 'TV' },
    { id: 37, name: 'Video game' },
    { id: 38, name: 'Western & cowboy' },
    { id: 39, name: 'Zodiac' }
];

function Sections({ storeId }) {
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});

    useEffect(() => {
        if (storeId) {
            fetchDatabaseSections();
            fetchKeywords();
        }
    }, [storeId]);

    const fetchDatabaseSections = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/sections-from-db`);
            setSections(response.data.sections);

            const keywordsObject = response.data.sections.reduce((acc, section) => {
                acc[section.shop_section_id] = section.keywords || '';
                return acc;
            }, {});
            setKeywords(keywordsObject);
        } catch (error) {
            toast.error('Failed to fetch sections from database.');
        } finally {
            setLoading(false);
        }
    };

    const syncSectionsFromEtsy = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('etsyAccessToken');
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/sections`, {
                headers: { etsyAccessToken: token },
            });

            setSections(response.data.sections);
            toast.success('Sections synchronized successfully.');
        } catch (error) {
            toast.error('Failed to sync sections from Etsy.');
        } finally {
            setLoading(false);
        }
    };

    const handleKeywordChange = (sectionId, newKeywords) => {
        setKeywords((prevKeywords) => ({
            ...prevKeywords,
            [sectionId]: newKeywords,
        }));
    };
    

    const saveKeywords = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found');
    
            await axios.post(`https://etsyhelper.onrender.com/stores/save-keywords`, {
                store_id: storeId,
                sections: sections.map((section) => ({
                    shop_section_id: section.shop_section_id,
                    keywords: keywords[section.shop_section_id],
                    dropdownOptions: (selectedOptions[section.shop_section_id] || []).map(opt => opt.value)
                })),
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
    
            toast.success('Keywords and selections saved successfully.');  // Başarı mesajı
        } catch (error) {
            console.error('Error saving keywords:', error);
            toast.error('Failed to save keywords.');  // Hata mesajı
        }
    };
    

    const fetchKeywords = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found');
    
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/get-keywords/${storeId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
    
            // Anahtar kelimeler ve seçili sanat konularını ayarlamak için iki ayrı state objesi
            const keywordsObject = response.data.sections.reduce((acc, section) => {
                acc[section.shop_section_id] = section.keywords || '';
                return acc;
            }, {});
    
            const selectedOptionsObject = response.data.sections.reduce((acc, section) => {
                acc[section.shop_section_id] = section.art_subject_id
                    ? section.art_subject_id.split(',').map(id => ({
                        value: parseInt(id, 10),  // id'yi sayıya çeviriyoruz
                        label: art_subjects.find(subject => subject.id === parseInt(id, 10))?.name || ''
                    }))
                    : [];  // art_subject_id boşsa, boş liste döndür
    
                return acc;
            }, {});
    
            setKeywords(keywordsObject);  // Keywords state'ini güncelle
            setSelectedOptions(selectedOptionsObject);  // Sanat konularını seçili olarak ayarla
    
        } catch (error) {
            console.error('Error fetching keywords:', error);
            toast.error('Failed to fetch keywords.');
        }
    };
    
    

    const handleDropdownChange = (sectionId, selectedValues) => {
        if (selectedValues.length > 3) {
            toast.warn('You can select up to 3 subjects only.');
            return;
        }
    
        setSelectedOptions((prev) => ({
            ...prev,
            [sectionId]: selectedValues
        }));
    };
    

    if (loading) return <p>Loading sections...</p>;

    return (
        <div>
            <h3 className="text-xl font-bold mb-4">Store Sections</h3>
            <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md mb-4 hover:bg-blue-600"
                onClick={syncSectionsFromEtsy}
            >
                Sync with Etsy
            </button>

            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b bg-gray-100 font-semibold text-gray-700">Sections</th>
                        <th className="py-2 px-4 border-b bg-gray-100 font-semibold text-gray-700">Keywords</th>
                        <th className="py-2 px-4 border-b bg-gray-100 font-semibold text-gray-700">Art Subjects</th>
                    </tr>
                </thead>
                <tbody>
                    {sections.map((section) => (
                        <tr key={section.shop_section_id} className="hover:bg-gray-50">
                            <td className="py-2 px-4 border-b">{section.title}</td>
                            <td className="py-2 px-4 border-b">
                                <input
                                    type="text"
                                    placeholder="Enter keywords"
                                    value={keywords[section.shop_section_id] || ''}
                                    onChange={(e) => handleKeywordChange(section.shop_section_id, e.target.value)}
                                    className="w-full p-2 border rounded-md"
                                />
                            </td>
                            <td className="py-2 px-4 border-b">
                                <Select
                                    isMulti
                                    options={art_subjects.map(subject => ({
                                        value: subject.id,
                                        label: subject.name
                                    }))}
                                    value={selectedOptions[section.shop_section_id] || []}  // Önceden seçili değerler burada ayarlandı
                                    onChange={(selected) => handleDropdownChange(section.shop_section_id, selected)}
                                    placeholder="Select up to 3 options"
                                    className="w-full"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button
                className="bg-green-500 text-white py-2 px-4 rounded-md mt-4 hover:bg-green-600"
                onClick={saveKeywords}
            >
                Save Keywords
            </button>
            {/* Toast Container */}
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Sections;
