import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function BulkUpload() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [aspectRatios, setAspectRatios] = useState({});
  const [variationProfiles, setVariationProfiles] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);

  const select_frame_room_and_offset = (aspect_ratio) => {
    if (0.90 <= aspect_ratio && aspect_ratio <= 1.10) {
      return 'Kare';
    } else if (4/7 * 0.90 <= aspect_ratio && aspect_ratio <= 4/7 * 1.10) {
      return 'Uzun Dikey';
    } else if (7/4 * 0.90 <= aspect_ratio && aspect_ratio <= 7/4 * 1.10) {
      return 'Uzun Yatay';
    } else if (1/2 * 0.90 <= aspect_ratio && aspect_ratio <= 1/2 * 1.10) {
      return 'aspect_1_2';
    } else if (2/1 * 0.90 <= aspect_ratio && aspect_ratio <= 2/1 * 1.10) {
      return 'aspect_2_1';
    } else if (aspect_ratio > 1) {
      return 'Yatay';
    } else {
      return 'Dikey';
    }
  };

  const onDrop = (acceptedFiles) => {
    if (files.length + acceptedFiles.length <= 20) {
      const updatedFiles = [...files, ...acceptedFiles];
      setFiles(updatedFiles);
      updatedFiles.forEach(file => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          setAspectRatios(prev => ({ ...prev, [file.name]: aspectRatio }));
          const variationProfile = select_frame_room_and_offset(aspectRatio);
          setVariationProfiles(prev => ({ ...prev, [file.name]: variationProfile }));
        };
      });
    } else {
      setError('En fazla 20 dosya yükleyebilirsiniz.');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': []
    },
    maxFiles: 20,
    onDropRejected: () => {
      setError('En fazla 20 dosya yükleyebilirsiniz.');
    }
  });

  const handleUpload = async () => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    try {
      setUploading(true);
      setError(null);
      setMessage(null);
      setUploadProgress(0);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token bulunamadı');
      }

      const response = await axios.post('https://etsyhelper.onrender.com/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(Math.round(progress));
        },
      });

      if (response.data.message === 'Products uploaded successfully') {
        setMessage('Ürünler başarıyla yüklendi');
        setTimeout(() => {
          navigate('/all-products');
        }, 2000);
      } else {
        const errorMessage = response.data.message || 'Ürünler yüklenirken hata oluştu';
        setError(errorMessage);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Dosyalar yüklenirken hata oluştu';
      setError(errorMessage);
    } finally {
      setUploading(false);
    }
  };

  const removeFile = (file) => {
    setFiles(files.filter(f => f !== file));
    setAspectRatios(prev => {
      const updated = { ...prev };
      delete updated[file.name];
      return updated;
    });
    setVariationProfiles(prev => {
      const updated = { ...prev };
      delete updated[file.name];
      return updated;
    });
  };

  return (
    <div className="container mx-auto p-6">
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="bulk-upload-area p-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Toplu Resim Yükleme</h2>
          <p className="text-gray-600 mb-8">Tek seferde 20 adete kadar resim yükleyebilirsiniz</p>

          <div
            {...getRootProps()}
            className={`
              border-2 border-dashed rounded-xl p-8 mb-6 text-center cursor-pointer
              transition-all duration-200 ease-in-out
              ${isDragActive 
                ? 'border-purple-500 bg-purple-50' 
                : 'border-gray-300 hover:border-purple-400 hover:bg-gray-50'
              }
            `}
          >
            <input {...getInputProps()} />
            <svg 
              className="mx-auto h-12 w-12 text-gray-400" 
              stroke="currentColor" 
              fill="none" 
              viewBox="0 0 48 48" 
              aria-hidden="true"
            >
              <path 
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
              />
            </svg>
            <p className="mt-4 text-sm text-gray-600">
              {isDragActive
                ? "Dosyaları buraya bırakın..."
                : "Dosyaları sürükleyip bırakın veya seçmek için tıklayın"}
            </p>
            <p className="mt-2 text-xs text-gray-500">
              PNG, JPEG veya WEBP (Maks. 20 dosya)
            </p>
          </div>

          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
              {error}
            </div>
          )}

          {message && (
            <div className="mb-6 bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded-lg">
              {message}
            </div>
          )}

          {files.length > 0 && (
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <p className="text-sm text-gray-600">
                  {files.length} dosya seçildi
                </p>
                <button
                  onClick={handleUpload}
                  disabled={uploading}
                  className={`
                    px-4 py-2 rounded-lg text-white font-medium
                    transition-colors duration-200
                    ${uploading
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-purple-600 hover:bg-purple-700'
                    }
                  `}
                >
                  {uploading ? (
                    <div className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Yükleniyor
                    </div>
                  ) : (
                    'Yükle'
                  )}
                </button>
              </div>

              {uploading && (
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div 
                    className="bg-purple-600 h-2 rounded-full transition-all duration-300"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              )}

              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {files.map((file, index) => (
                  <div key={index} className="group relative bg-white rounded-lg shadow overflow-hidden">
                    <div className="aspect-w-1 aspect-h-1">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`preview-${index}`}
                        className="w-full h-full object-cover"
                      />
                      <button
                        onClick={() => removeFile(file)}
                        className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                      >
                        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div className="p-2">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                        {variationProfiles[file.name]}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BulkUpload;