import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import StaticImageUpload from './StaticImageUpload';

const ItemType = 'MOCKUP_SET';

const DraggableMockupSet = ({ set, index, moveMockupSet, handleDeleteSet }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index }
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveMockupSet(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  return (
    <li ref={(node) => ref(drop(node))} className="bg-white p-4 rounded-lg shadow-md mb-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <div>
            <h4 className="text-lg font-semibold">Room Image</h4>
            <img src={set.room_url} alt={`room-${set.id}`} className="w-20 h-20 object-contain rounded" />
          </div>
          {set.frame_url && (
            <div>
              <h4 className="text-lg font-semibold">Frame Image</h4>
              <img src={set.frame_url} alt={`frame-${set.id}`} className="w-20 h-20 object-contain rounded" />
            </div>
          )}
        </div>
        <button
          onClick={() => handleDeleteSet(set.id)}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
        >
          Sil
        </button>
      </div>
    </li>
  );
};

const ImagesTab = ({ profileId }) => {
  const [mockupSets, setMockupSets] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchMockupSets();
  }, [profileId]);

  const fetchMockupSets = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://etsyhelper.onrender.com/profile_images?profile_id=${profileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMockupSets(response.data);
    } catch (error) {
      console.error('Error fetching mockup sets:', error);
      setError('Failed to fetch mockup sets');
    }
  };

  const handleDeleteSet = async (setId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://etsyhelper.onrender.com/profile_images/${setId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMockupSets(mockupSets.filter(set => set.id !== setId));
      toast.success('Mockup set deleted successfully');
    } catch (error) {
      console.error('Error deleting mockup set:', error);
      toast.error('Failed to delete mockup set');
    }
  };

  const moveMockupSet = (dragIndex, hoverIndex) => {
    const draggedMockupSet = mockupSets[dragIndex];
    const updatedSets = update(mockupSets, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedMockupSet]
      ]
    });

    // Update the list numbers based on the new order
    const reorderedSets = updatedSets.map((set, idx) => ({
      ...set,
      list_number: idx
    }));

    setMockupSets(reorderedSets);

    // Save the new order to the database
    const token = localStorage.getItem('token');
    axios.put('https://etsyhelper.onrender.com/profile_images/reorder', 
      reorderedSets.map(set => ({ 
        id: set.id, 
        list_number: set.list_number 
      })), {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(() => toast.success('Order saved successfully'))
      .catch(error => {
        console.error('Error saving order:', error);
        toast.error('Failed to save order');
      });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {error && <p className="text-red-500">{error}</p>}
        
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Mockup Setleri</h3>
          <p className="text-sm text-gray-600 mb-4">
            Setleri sürükleyerek sıralayabilir veya silmek için "Sil" butonunu kullanabilirsiniz.
          </p>
          <ul className="space-y-4">
            {mockupSets.map((set, index) => (
              <DraggableMockupSet
                key={set.id}
                index={index}
                set={set}
                moveMockupSet={moveMockupSet}
                handleDeleteSet={handleDeleteSet}
              />
            ))}
          </ul>
        </div>

        <div className="container mx-auto mt-10">
          <StaticImageUpload 
            profileId={profileId} 
            fetchStaticImages={() => {}} 
          />
        </div>
      </div>
    </DndProvider>
  );
};

export default ImagesTab;