import React, { useState, useEffect } from 'react';
import axios from 'axios';

function CustomerVariations({ profileId }) {
  const [variations, setVariations] = useState([]);
  const [friendlyDimensionNames, setFriendlyDimensionNames] = useState({});
  const [friendlyFrameNames, setFriendlyFrameNames] = useState({});
  const [prices, setPrices] = useState({});
  const [randomQuantity, setRandomQuantity] = useState({ min: 1, max: 10 });
  const [quantities, setQuantities] = useState({});
  const [deactivated, setDeactivated] = useState({});
  const [isRandomActive, setIsRandomActive] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState('cm');
  

  const availableUnits = [
    { value: 'cm', label: 'Centimeters (cm)' },
    { value: 'inches', label: 'Inches (in)' }
  ];

  // Boyutları numerik olarak sıralamak için yardımcı fonksiyon
  const sortDimensions = (variations) => {
    return variations.sort((a, b) => {
      const [aWidth] = a.dimension_name.split('x').map(num => parseInt(num));
      const [bWidth] = b.dimension_name.split('x').map(num => parseInt(num));
      return aWidth - bWidth;
    });
  };

  // Orijinal isimleri kullanma fonksiyonu
  const useOriginalNames = () => {
    const newFriendlyDimensionNames = {};
    const newFriendlyFrameNames = {};

    variations.forEach((variation) => {
      if (!friendlyDimensionNames[variation.id]) {
        newFriendlyDimensionNames[variation.id] = variation.dimension_name;
      }
      if (!friendlyFrameNames[variation.id]) {
        newFriendlyFrameNames[variation.id] = variation.frame_name;
      }
    });

    setFriendlyDimensionNames({
      ...friendlyDimensionNames,
      ...newFriendlyDimensionNames
    });
    setFriendlyFrameNames({
      ...friendlyFrameNames,
      ...newFriendlyFrameNames
    });
  };


  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('No token found');
      return;
    }

    // Varyasyonları getiriyoruz
    axios
      .get(`https://api.canvashelper.com/api/get-variations/${profileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const sortedVariations = sortDimensions(response.data);
        setVariations(sortedVariations);
      })
      .catch((error) => {
        console.error('Error fetching variations:', error);
      });

    // Daha önce kaydedilmiş varyasyonları getiriyoruz
    axios
      .get(`https://api.canvashelper.com/api/get-saved-customer-variations/${profileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const savedVariations = response.data;
        if (savedVariations.length > 0) {
          setSelectedUnit(savedVariations[0].unit);  // İlk gelen varyasyonun unit'ini set et
        }
        
        const friendlyDims = {};
        const friendlyFrms = {};
        const pricesData = {};
        const quantitiesData = {};
        const deactivatedData = {};

        savedVariations.forEach((savedVariation) => {
          friendlyDims[savedVariation.variation_id] = savedVariation.friendly_dimension_name || '';
          friendlyFrms[savedVariation.variation_id] = savedVariation.friendly_frame_name || '';
          pricesData[savedVariation.variation_id] = savedVariation.price || '';
          quantitiesData[savedVariation.variation_id] = savedVariation.quantity || '';
          deactivatedData[savedVariation.variation_id] = !savedVariation.is_enabled;
        });

        setFriendlyDimensionNames(friendlyDims);
        setFriendlyFrameNames(friendlyFrms);
        setPrices(pricesData);
        setQuantities(quantitiesData);
        setDeactivated(deactivatedData);
      })
      .catch((error) => {
        console.error('Error fetching saved customer variations:', error);
      });
  }, [profileId]);

  const generateRandomQuantity = () => {
    const min = parseInt(randomQuantity.min, 10);
    const max = parseInt(randomQuantity.max, 10);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleRandomCheckbox = () => {
    setIsRandomActive(!isRandomActive);
    if (!isRandomActive) {
      const updatedQuantities = variations.reduce((acc, variation) => {
        acc[variation.id] = generateRandomQuantity();
        return acc;
      }, {});
      setQuantities(updatedQuantities);
    } else {
      setQuantities({});
    }
  };

  useEffect(() => {
    if (isRandomActive) {
      const updatedQuantities = variations.reduce((acc, variation) => {
        acc[variation.id] = generateRandomQuantity();
        return acc;
      }, {});
      setQuantities(updatedQuantities);
    }
  }, [randomQuantity.min, randomQuantity.max]);

  const handleQuantityChange = (id, value) => {
    setQuantities({ ...quantities, [id]: value });
  };

  const handleDeactivate = (id) => {
    setDeactivated({ ...deactivated, [id]: !deactivated[id] });
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('No token found');
      return;
    }

    const dataToSave = variations.map((variation) => ({
      variation_id: variation.id,
      friendly_dimension_name: friendlyDimensionNames[variation.id] || '',
      friendly_frame_name: friendlyFrameNames[variation.id] || '',
      price: prices[variation.id] || 0,
      quantity: quantities[variation.id] || (isRandomActive ? generateRandomQuantity() : 0),
      is_enabled: !deactivated[variation.id],
      unit: selectedUnit,
    }));

    try {
      await axios.post(
        'https://api.canvashelper.com/api/save-customer-variations',
        { variations: dataToSave },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Varyasyonlar başarıyla kaydedildi!');
    } catch (error) {
      console.error('Error saving variations:', error);
      alert('Varyasyonlar kaydedilirken bir hata oluştu.');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-semibold mb-6">Customer Variations</h2>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="flex flex-col">
          <label className="text-gray-700">Min Quantity:</label>
          <input
            type="number"
            value={randomQuantity.min}
            onChange={(e) => setRandomQuantity({ ...randomQuantity, min: parseInt(e.target.value, 10) || 1 })}
            className="border rounded-lg px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-700">Max Quantity:</label>
          <input
            type="number"
            value={randomQuantity.max}
            onChange={(e) => setRandomQuantity({ ...randomQuantity, max: parseInt(e.target.value, 10) || 1 })}
            className="border rounded-lg px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-gray-700">Measurement Unit:</label>
          <select
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.target.value)}
            className="border rounded-lg px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {availableUnits.map((unit) => (
              <option key={unit.value} value={unit.value}>
                {unit.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-6">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={isRandomActive}
            onChange={handleRandomCheckbox}
            className="mr-2"
          />
          Random Quantity Aktif
        </label>
        <button
          onClick={useOriginalNames}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
        >
          Use Original Names
        </button>
      </div>

      <div className="overflow-y-auto max-h-80">
        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 border">Dimension</th>
              <th className="px-4 py-2 border">Frame</th>
              <th className="px-4 py-2 border">Friendly Dimension Name</th>
              <th className="px-4 py-2 border">Friendly Frame Name</th>
              <th className="px-4 py-2 border">Price</th>
              <th className="px-4 py-2 border">Quantity</th>
              <th className="px-4 py-2 border">Deactivate</th>
            </tr>
          </thead>
          <tbody>
            {variations.map((variation) => (
              <tr key={variation.id} className="hover:bg-gray-50">
                <td className="px-4 py-2 border">{variation.dimension_name}</td>
                <td className="px-4 py-2 border">{variation.frame_name}</td>
                <td className="px-4 py-2 border">
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      placeholder="Friendly Dimension Name"
                      value={friendlyDimensionNames[variation.id] || ''}
                      onChange={(e) =>
                        setFriendlyDimensionNames({
                          ...friendlyDimensionNames,
                          [variation.id]: e.target.value,
                        })
                      }
                      className="border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <span className="text-gray-500">({selectedUnit})</span>
                  </div>
                </td>
                <td className="px-4 py-2 border">
                  <input
                    type="text"
                    placeholder="Friendly Frame Name"
                    value={friendlyFrameNames[variation.id] || ''}
                    onChange={(e) =>
                      setFriendlyFrameNames({
                        ...friendlyFrameNames,
                        [variation.id]: e.target.value,
                      })
                    }
                    className="border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </td>
                <td className="px-4 py-2 border">
                  <input
                    type="number"
                    placeholder="Price"
                    value={prices[variation.id] || ''}
                    onChange={(e) =>
                      setPrices({ ...prices, [variation.id]: e.target.value })
                    }
                    className="border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </td>
                <td className="px-4 py-2 border">
                  <input
                    type="number"
                    placeholder="Quantity"
                    value={quantities[variation.id] || (isRandomActive ? '' : '')}
                    onChange={(e) => handleQuantityChange(variation.id, e.target.value)}
                    className="border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    disabled={isRandomActive}
                  />
                </td>
                <td className="px-4 py-2 border">
                  <button
                    onClick={() => handleDeactivate(variation.id)}
                    className={`px-4 py-2 rounded-lg text-white ${
                      deactivated[variation.id]
                        ? 'bg-red-500 hover:bg-red-600'
                        : 'bg-green-500 hover:bg-green-600'
                    }`}
                  >
                    {deactivated[variation.id] ? 'Activate' : 'Deactivate'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        onClick={handleSave}
        className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600"
      >
        Save Variations
      </button>
    </div>
  );
}

export default CustomerVariations;
