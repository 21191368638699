// components/StoreProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const StoreProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [checkingStore, setCheckingStore] = useState(true);
  const [canAccess, setCanAccess] = useState(false);

  useEffect(() => {
    const checkStoreStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        
        // Mağaza listesini kontrol et
        const storesResponse = await axios.get('https://etsyhelper.onrender.com/stores/list', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (storesResponse.data.length > 0) {
          // Seçili mağazayı kontrol et
          const selectedStoreResponse = await axios.get('https://etsyhelper.onrender.com/stores/get-selected-store', {
            headers: { Authorization: `Bearer ${token}` },
          });

          const lastSelectedStoreId = selectedStoreResponse.data.last_selected_store_id;
          
          // Hem mağaza var hem de seçili mağaza varsa erişime izin ver
          setCanAccess(!!lastSelectedStoreId);
        } else {
          setCanAccess(false);
        }
      } catch (error) {
        console.error('Store check error:', error);
        setCanAccess(false);
      } finally {
        setCheckingStore(false);
      }
    };

    if (isAuthenticated) {
      checkStoreStatus();
    }
  }, [isAuthenticated]);

  // Loading durumu
  if (checkingStore) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <l-quantum size="45" speed="1.75" color="black"></l-quantum>
      </div>
    );
  }

  // Eğer erişim yoksa direkt stores sayfasına yönlendir
  if (!canAccess) {
    return <Navigate to="/my-account/stores" replace />;
  }

  // Her şey yolunda ise sayfayı göster
  return children;
};

export default StoreProtectedRoute;