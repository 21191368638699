import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const TourGuide = () => {
  const [runTour, setRunTour] = useState(false);
  const [tutorials, setTutorials] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Sayfa ve tutorial ID eşleştirmeleri
  const pageToTutorialId = {
    '/bulk-upload': 1,        // Bulk Upload
    '/mockup': 2,             // Mockup Creator
    '/description': 3,        // Description Generator
    '/my-account/stores': 4,  // Stores
    '/variations': 5,         // Variation Profiles
    '/preparation': 6         // Product Preparation
  };

  // Farklı sayfalar için farklı adımlar
  const tourSteps = {
    // Ana sayfa adımları
    '/bulk-upload': [
      {
        target: '.sidebar',
        content: 'Buradan tüm menülere erişebilirsiniz',
        placement: 'right',
      },
      {
        target: '.header-store-selector',
        content: 'Mağazalarınız arasında geçiş yapabilirsiniz',
        placement: 'bottom',
      },
      {
        target: '.bulk-upload-area',
        content: 'Buradan toplu ürün yüklemesi yapabilirsiniz',
        placement: 'bottom',
      }
    ],
    // Ürün listesi sayfası adımları
    '/all-products': [
      {
        target: '.product-filters',
        content: 'Ürünlerinizi burada filtreleyebilirsiniz',
        placement: 'bottom',
      },
      {
        target: '.product-list',
        content: 'Tüm ürünleriniz burada listelenir',
        placement: 'top',
      }
    ],
    // Mağaza sayfası adımları
    '/my-account/stores': [
      {
        target: '.add-store-button',
        content: 'Yeni mağaza eklemek için bu butonu kullanın',
        placement: 'bottom',
      },
      {
        target: '.store-list',
        content: 'Mevcut mağazalarınızı buradan yönetebilirsiniz',
        placement: 'top',
      }
    ]
  };

  const fetchTutorials = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://etsyhelper.onrender.com/api/tutorials', {
        headers: { Authorization: `Bearer ${token}` },
    });
      setTutorials(response.data);
    } catch (error) {
      console.error('Error fetching tutorials:', error);
    } finally {
      setLoading(false);
    }
  };

  // Sayfa yüklendiğinde ve değiştiğinde tutorial durumlarını kontrol et
  useEffect(() => {
    fetchTutorials();
  }, []);

  useEffect(() => {
    if (!loading && location.pathname) {
      const tutorialId = pageToTutorialId[location.pathname];
      if (tutorialId) {
        const tutorial = tutorials.find(t => t.id === tutorialId);
        if (tutorial && tutorial.status === 'not_started') {
          setRunTour(true);
        }
      }
    }
  }, [location.pathname, tutorials, loading]);

  // Tutorial durumunu güncelle
  const updateTutorialStatus = async (tutorialId, status) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        'https://etsyhelper.onrender.com/api/tutorials/status', 
        {
          tutorial_id: tutorialId,
          status
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      // Tutorial listesini güncelle
      fetchTutorials();
    } catch (error) {
      console.error('Error updating tutorial status:', error);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const tutorialId = pageToTutorialId[location.pathname];
    
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      
      if (tutorialId) {
        // Tutorial durumunu güncelle
        updateTutorialStatus(
          tutorialId, 
          status === STATUS.FINISHED ? 'completed' : 'skipped'
        );
      }
    }
  };

  // Tour'u manuel başlatmak için fonksiyon
  const startTour = () => {
    setRunTour(true);
  };

  // Tutorial'ları sıfırlamak için fonksiyon
  const resetTutorials = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://etsyhelper.onrender.com/api/tutorials/reset', {
        headers: { Authorization: `Bearer ${token}` },
    });
      await fetchTutorials();
      // Mevcut sayfanın tutorial'ını başlat
      const tutorialId = pageToTutorialId[location.pathname];
      if (tutorialId) {
        setRunTour(true);
      }
    } catch (error) {
      console.error('Error resetting tutorials:', error);
    }
  };

  if (loading) {
    return null; // veya loading spinner
  }

  return (
    <>
      <Joyride
        steps={tourSteps[location.pathname] || []}
        run={runTour}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: '#3b82f6',
            zIndex: 1000,
          },
          tooltipContainer: {
            textAlign: 'left',
          },
          buttonNext: {
            backgroundColor: '#3b82f6',
          },
          buttonBack: {
            marginRight: 10,
          }
        }}
        locale={{
          back: 'Geri',
          close: 'Kapat',
          last: 'Bitir',
          next: 'İleri',
          skip: 'Geç'
        }}
      />
      
      {/* Yardım menüsü */}
      <div className="fixed bottom-4 right-4 flex flex-col gap-2">
        {/* Tutorial'ları sıfırla butonu */}
        <button
          onClick={resetTutorials}
          className="bg-gray-500 text-white p-2 rounded-full shadow-lg hover:bg-gray-600 transition-colors"
          aria-label="Tüm öğreticileri sıfırla"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </button>
        
        {/* Yardım butonu */}
        <button
          onClick={startTour}
          className="bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
          aria-label="Yardım"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default TourGuide;