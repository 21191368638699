import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalImage from 'react-modal-image';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ActiveProductList = () => {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 10;

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://etsyhelper.onrender.com/api/active-products', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            setProducts(response.data);
        })
        .catch(error => {
            console.error("Error fetching active products:", error);
            toast.error("Failed to fetch active products.");
        });
    }, []);

    // Sayfalama (pagination) işlemleri
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const paginatedProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(products.length / productsPerPage);

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-4">Active Products</h1>
            {paginatedProducts.length > 0 ? (
                <ul className="space-y-4">
                    {paginatedProducts.map((product) => (
                        <li key={product.id} className="bg-white shadow rounded-lg overflow-hidden flex items-center p-4">
                            <ModalImage
                                small={product.base_image}
                                large={product.base_image}
                                alt=''
                                className="w-32 h-32 object-cover rounded-md"
                            />
                            <div className="flex-grow ml-4">
                                <h3 className="text-lg font-semibold">{product.name}</h3>
                                <p className="text-gray-600">Profile: {product.variation_profile_name}</p>
                                <p className="text-gray-600">Section: {product.section_title}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No active products available.</p>
            )}
            {/* Pagination */}
            <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentPage(index + 1)}
                        className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
            <ToastContainer />
        </div>
    );
};

export default ActiveProductList;
